import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import { Button } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { InlineWidget } from "react-calendly";
import { Helmet } from "react-helmet";
import Hero from "../components/hero";
import Layout from "../components/layout";
import yellowOne from "../images/1-yellow.svg";
import yellowTwo from "../images/2-yellow.svg";
import yellowThree from "../images/3-yellow.svg";
import CalendarFormSwitcher from "../components/CalendarFormSwitcher";

const PersonalBrandingPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Hero-Homepage" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
				mediaDetails {
					height
					width
				}
			}
			sideImg1: wpMediaItem(title: { eq: "Google-image" }) {
				altText
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			yourBrandImg: wpMediaItem(title: { eq: "Personal Brand 1" }) {
				altText
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
				link
			}
			linvaLogoImg: wpMediaItem(title: { eq: "lingvahive-logo" }) {
				altText
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
				link
			}
			RyanImg: wpMediaItem(title: { eq: "Ryan-Dixon-Property-Logo-Master" }) {
				altText
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
				link
			}
			TootLogoImg: wpMediaItem(title: { eq: "Toot-Hill-Master-Logo 1" }) {
				altText
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
				link
			}
			personalImg2: wpMediaItem(title: { eq: "Personal Brand 2" }) {
				altText
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
				link
			}

			wpSeoPage(title: { eq: "Personal Brand" }) {
				contentForHeadTag {
					content
				}
				seo {
					metaDesc
					title
					twitterImage {
						altText
						gatsbyImage(
							width: 1920
							quality: 75
							formats: [WEBP]
							layout: CONSTRAINED
							placeholder: BLURRED
						)
						mediaDetails {
							height
							width
						}
						link
					}
					opengraphTitle
					opengraphDescription
				}
			}
			icon: wpMediaItem(title: { eq: "icon" }) {
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
				link
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const schema = data.wpSeoPage.contentForHeadTag.content;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Personal Brand",
				item: {
					url: `${siteUrl}/personal-brand`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<Helmet>
				{" "}
				<script type="application/ld+json">{schema}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title={data.wpSeoPage.seo.title}
				description={data.wpSeoPage.seo.metaDesc}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/personal-brand`,
					title: `${data.wpSeoPage.seo.opengraphTitle}`,
					description: `${data.wpSeoPage.seo.opengraphDescription}`,
					images: [
						{
							url: `${data.wpSeoPage.seo.twitterImage.link}`,
							width: `${data.wpSeoPage.seo.twitterImage.mediaDetails.width}`,
							height: `${data.wpSeoPage.seo.twitterImage.mediaDetails.height}`,
							alt: `${data.wpSeoPage.seo.twitterImage.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<section>
					<Hero
						button1="primary-link-dark"
						button1Text="Talk to an expert"
						button1Link="#form"
						button1Var="white"
						button2="white-link-yellow"
						button2Text="Get in touch"
						button2Link="/contact-us"
						button2Var="primary"
						button3={null}
						backgroundImage={data.heroImg.gatsbyImage}
						title={`Personal \n<span class="text-secondary">branding</span>`}
						description="Helping you to build a brand that represents you."
					/>
				</section>
				{/* <section className="py-5 py-lg-7 bg-light-grey d-none d-lg-block">
					<Container className="p-5 bg-white">
						<Row className="justify-content-center gy-4">
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#intro"
								>
									Introduction
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#what-we-do"
								>
									What we do
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#faq"
								>
									FAQ's
								</a>
							</Col>
						</Row>
					</Container>
				</section> */}
				<section id="intro" className="bg-light-grey py-5 py-lg-7">
					<Container>
						<Row className="align-items-center gx-lg-6 gx-xl-7">
							<Col lg={6} className=" ">
								<h2 className="display-5 text-primary mb-5">
									The importance of building a personal brand
								</h2>
								<p>
									In today's world, a strong brand is essential for establishing
									credibility, building trust, and encouraging customer loyalty.
									Your brand is more than just a logo and a colour scheme, it
									represents who you are and highlights your values to potential
									customers.
								</p>
								<p>
									Our experienced team will work closely with you to develop a
									brand that highlights your unique vision and resonates with
									your ideal customers. At RJM Digital we know that every
									individual is different, and we take time to learn about your
									industry, competition, and your audience before we begin
									creating your own personal brand.
								</p>
								<p>
									Are you ready to build a brand that leaves a lasting impact?
								</p>

								<Button
									className=" w-100 cta-btn w-md-auto me-xl-4  fs-5 white-link-yellow py-2 px-4 mb-5 mb-lg-0 mt-4 "
									variant="primary"
									href="#form"
								>
									Talk to an expert today
								</Button>
								{/* <Button
									className=" w-100 cta-btn w-md-auto fs-5 d-none d-xl-inline-block white-link-yellow py-2 px-4 mb-5 mb-lg-0 mt-4 "
									variant="primary"
									as={Link}
									to="/contact-us"
								>
									Get in touch
								</Button> */}
							</Col>
							<Col lg={6}>
								<div style={{ overflow: "hidden", borderRadius: "15px" }}>
									<GatsbyImage
										className="w-100"
										image={data.yourBrandImg.gatsbyImage}
										alt={data.yourBrandImg.altText}
									/>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
				<section id="benefits" className="py-5 py-lg-7 bg-dark-grey">
					<Container className="">
						<Row className="justify-content-between align-items-center">
							<Col md={9} lg={8}>
								<h2 className="display-2 ssp-bold text-uppercase text-white mb-lg-5">
									Why you need
									<span>
										<br />{" "}
										<span className="text-secondary"> a strong brand</span>
									</span>
								</h2>
							</Col>
							{/* <Col md={6} lg={4} className="text-end">
								<StaticImage
									src="../../images/Google-Ads-Icon.svg"
									placeholder="blurred"
									quality={100}
									className="w-100"
								/>
							</Col> */}
						</Row>
						<Row className="gx-xl-9 gy-5 justify-content-center">
							<Col className="position-relative" md={6} lg={4}>
								<div
									style={{ top: "-7rem" }}
									className="position-absolute text-center w-100 h-100"
								>
									<img src={yellowOne} className="w-md-40 w-30" />
									<div className="px-xl-5 pt-5 position-relative">
										<h3 className="fs-1 text-white">Differentiation</h3>
										<p className="text-white">
											a strong brand gives you a unique identity that can help
											you to stand out in a crowded market.
										</p>
									</div>
								</div>
							</Col>
							<Col className="position-relative" md={6} lg={4}>
								<div
									style={{ top: "-7rem" }}
									className="position-absolute text-center w-100 h-100"
								>
									<img src={yellowTwo} className="w-md-40 w-30" />
								</div>
								<div className="px-xl-5 pt-5 position-relative">
									<h3 className="fs-1 text-white">Building trust</h3>
									<p className="text-white">
										A strong brand helps to build trust and credibility among
										your customers, as it represents consistency, quality, and
										reliability.
									</p>
								</div>
							</Col>
							<Col className="position-relative " md={6} lg={4}>
								<div
									style={{ top: "-7rem" }}
									className="position-absolute w-100 text-center h-100"
								>
									<img src={yellowThree} className="w-md-40 w-30" />
								</div>
								<div className="px-xl-5 pt-5 position-relative">
									<h3 className="fs-1 text-white">Better opportunities</h3>
									<p className="text-white">
										Open the door to more opportunities by creating your own
										personal brand separate from your franchise or business
										organisation, allowing you to express your own values.
									</p>
								</div>
							</Col>
						</Row>
						<Row>
							<Col
								style={{ zIndex: 9999 }}
								className="text-center position-relative"
							>
								<Button
									className=" w-100 cta-btn w-md-auto   fs-5 primary-link py-2 px-4 mb-5 mb-lg-0 mt-4 "
									variant="white"
									as={Link}
									to="/contact-us"
								>
									Make an inquiry now
								</Button>
							</Col>
						</Row>
					</Container>
				</section>
				<section className=" py-5 py-lg-7">
					<Container>
						<Row className="align-items-center justify-content-between gx-6 gx-xl-7">
							<Col lg={6}>
								<div
									className="mb-5 mb-lg-0"
									style={{
										overflow: "hidden",
										borderRadius: "15px",
									}}
								>
									<GatsbyImage
										className="w-100 "
										image={data.personalImg2.gatsbyImage}
										alt={data.personalImg2.altText}
									/>
								</div>
							</Col>
							<Col lg={6} className=" ">
								<h2 className="display-5 text-primary mb-5">
									Building a strong brand
									<br className="d-none d-xl-block" /> is key
								</h2>
								<p>
									A strong brand is key to success in any industry. It will help
									you to stand out from the competition and gain trust from
									customers, encouraging them to stay loyal to your products or
									services, rather than shopping elsewhere. With a strong brand,
									you can attract new customers and open doors to new
									opportunities, setting your personal brand up for long-term
									success. So it is crucial that your website reflects you and
									your values in the best way.
								</p>
								<Button
									className=" w-100 cta-btn w-md-auto   fs-5 white-link-yellow py-2 px-4 mb-5 mb-lg-0 mt-4 "
									variant="primary"
									as={Link}
									to="/contact-us"
								>
									Find out how we can help you
								</Button>
							</Col>
						</Row>
					</Container>
				</section>
				<section
					id="what-we-do"
					className="bg-light-grey position-relative pb-5 pb-lg-7"
				>
					<div className="position-relative bg-dark-grey">
						<Container className="pb-10 pt-7">
							<Row className="align-items-center ">
								<Col lg={6} className=" ">
									<h2 className=" text-white display-5 mb-4">
										Building you a brand that works for you
									</h2>
									<p className="text-white">
										At RJM Digital we have built many brands for both
										individuals and businesses that effectively represent their
										values and who they are in their industry. Regardless of
										whether you are building yourself a new brand or if you are
										improving an existing one, we are here to help you.
									</p>
									<p className="text-white">
										Below are some examples of branding work that we have
										created for our customers.
									</p>
									<Button
										className=" w-100 cta-btn w-md-auto   fs-5 primary-link py-2 px-4 mb-5 mb-lg-0 mt-4 "
										variant="white"
										as={Link}
										to="/portfolio"
									>
										View our portfolio
									</Button>
								</Col>
							</Row>
						</Container>
					</div>
					<div style={{ top: "-5rem" }} className="position-relative">
						<Container>
							<Row className="g-6">
								<Col lg={4}>
									<div
										className="bg-white d-flex justify-content-center px-4 align-items-center"
										style={{
											borderRadius: " 15px",
											boxShadow: "0px 3px 50px #00000012",
											width: "100%",
											height: "25rem",
										}}
									>
										<div className="d-block">
											<GatsbyImage
												className="w-100 "
												image={data.linvaLogoImg.gatsbyImage}
												alt={data.linvaLogoImg.altText}
											/>
										</div>
									</div>
								</Col>
								<Col lg={4}>
									<div
										className="bg-white d-flex justify-content-center px-4 align-items-center"
										style={{
											borderRadius: " 15px",
											boxShadow: "0px 3px 50px #00000012",
											width: "100%",
											height: "25rem",
										}}
									>
										<div className="d-block">
											<GatsbyImage
												className="w-100 "
												image={data.RyanImg.gatsbyImage}
												alt={data.RyanImg.altText}
											/>
										</div>
									</div>
								</Col>
								<Col lg={4}>
									<div
										className="bg-white d-flex justify-content-center px-4 align-items-center"
										style={{
											borderRadius: " 15px",
											boxShadow: "0px 3px 50px #00000012",
											width: "100%",
											height: "25rem",
										}}
									>
										<div className="d-block">
											<GatsbyImage
												className="w-100 "
												image={data.TootLogoImg.gatsbyImage}
												alt={data.TootLogoImg.altText}
											/>
										</div>
									</div>
								</Col>
							</Row>
						</Container>
					</div>
				</section>
				{/* <Logos /> */}

				{/* <section className="pt-5 pb-md-5 py-lg-7 bg-light-grey">
					<ReviewSection page="Branding" />
				</section> */}
				{/* <div id="faq">
					<Faq page="Branding" />
				</div> */}

				<section className="pb-5 pb-lg-7  bg-light-grey">
					<Container>
						<Row id="form">
							<Col className="text-center">
								{" "}
								<h2 className="display-5 text-primary mb-3">
									Talk to an expert
								</h2>
								<p>
									Book a time and day that works for you through our online
									calendar to discuss your personal brand project.
								</p>
							</Col>
						</Row>
						<Row>
							<CalendarFormSwitcher />
						</Row>
					</Container>
				</section>

				{/* <section className="py-5 pb-lg-7" id="pricing">
					<Container>
						<Row>
							<Col>
								<h2 className=" text-center  display-5 mb-5 mt-5   pb-5 text-primary">
									PRICING
								</h2>
							</Col>
						</Row>
						<Row className="justify-content-center ">
							<PricingCardTraining
								btnColour="secondary"
								title="AD - HOC"
								subText="For specific queries about SEO or Google Ads for your website. "
								price="£55"
								hourly
								middle
							/>
							<PricingCardTraining
								btnColour="light-blue"
								title="MONTHLY"
								subText="For businesses who are looking for a long term support and training on upskilling their staff"
								price="£350"
								monthly
								middle
							/>
						</Row>
					</Container>
				</section> */}
				{/* <OSSection
          link1="/web-design"
          text1="WEB DESIGN"
          link2="/e-commerce-websites"
          text2="E-COMMERCE"
          image={data.sideImg1.gatsbyImage}
          imageAlt={data.sideImg1.altText}
        /> */}
			</Layout>
		</>
	);
};

export default PersonalBrandingPage;
